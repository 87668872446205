import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import ActionButton from "../../../features/ui/components/actionButton";
import ActionButtonGroup from "../../Employee/components/ActionButtonGroup";
import { NotificationManager } from "react-notifications";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import contactService from "../../../services/contactService";

const CreateEditContact = ({
  name,
  user,
  isEdit,
  reloadCallback,
  showUpdateButton,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const title =
    isEdit && user && user.firstName ? `Contact - ${user.firstName}  ` : "";

  useEffect(() => {
    console.log("isEdit", isEdit);
    if (isEdit && user && user.firstName) {
      console.log("firstName ", user.firstName);
      setValue("firstName", user.firstName);
      setValue("middleName", user.middleName);
      setValue("lastName", user.lastName);
      setValue("phone", user.phone);
      setValue("email", user.email);
    }
  }, [isEdit, user, setValue]);
  // },[user]);

  const onSubmitHandler = async (data) => {
    console.log(data);
    try {
      if (data.status === "1") data.status = true;
      else data.status = false;

      if (isEdit && user && user.id) {
        await updateFormData(user.id, data);
      } else {
        await saveFormData(data);
      }
    } catch (error) {
      alert(`Registration failed! ${error.message}`);
      console.error("Form submission error:", error);
    }
  };

  const saveFormData = async (data) => {
    console.log("DATA: ",data)
    contactService
      .createContact(data)
      .then(async (response) => {
        console.dir(response);

        // NotificationManager.success("Added new Contact!", "Success", 2000);
        if (response.data.status === 409) {
          NotificationManager.error(response.data.message, "Error!", 2500);
        } else if (response.data.status === 500) {
          NotificationManager.error("Internal server error", "Error!", 2500);
        } else {
          NotificationManager.success(response.data.message, "Success!", 2500);
          reset();
        }
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error(
          `${error.response.data.error}`,
          "Error!",
          2500
        );
      });
  };

  const updateFormData = async (userId, data) => {
    data.id = userId;
    contactService
      .updateContact(data, userId)
      .then(() => {
        NotificationManager.success("Updated Contact!", "Success", 2000);
        reloadCallbackFn();
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error("Error while updating Contact!", "Error!");
      });
  };

  const resetCallback = () => {
    reset();
  };
  const reloadCallbackFn = () => {
    reloadCallback();
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h4 className="fw-bold" style={{ textAlign: "-webkit-left" }}>
            <span className="text-muted fw-light">{title ? title : ""}</span>
          </h4>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3 row">
                  <label
                    htmlFor="firstName"
                    className="col-md-4 col-form-label"
                  >
                    First Name
                    {/* First Name{JSON.stringify(user)} */}
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="firstName"
                      {...register("firstName", {
                        required: "true",
                      })}
                    />
                    {errors.firstName && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "0.25rem", fontSize: "80%" }}
                      >
                        First name is required
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-3 row">
                  <label
                    htmlFor="middleName"
                    className="col-md-4 col-form-label"
                  >
                    Middle Name
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="middle_name"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-3 row">
                  <label
                    htmlFor="last_name"
                    className="col-md-4 col-form-label"
                  >
                    Last Name
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="lastName"
                      {...register("lastName", {
                        required: "true",
                      })}
                    />
                    {errors.lastName && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "0.25rem", fontSize: "80%" }}
                      >
                        Last name is required
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-3 row">
                  <label htmlFor="phone" className="col-md-4 col-form-label">
                    Phone
                  </label>
                  <div className="col-md-8">
                    {/* <input
                      className="form-control"
                      type="text"
                      id="phone"
                      {...register("phone", {
                        required: "Phone number is required",
                        pattern: {
                         
                          value : /^[\+0-9]{6,15}$/,
                          message: "Please enter a valid phone number",
                        },
                      })}
                    />
                    {errors.phone && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "0.25rem", fontSize: "80%" }}
                      >
                        {errors.phone.message}
                      </div>
                    )} */}

                    <Controller
                    
                      name="phone"
                      control={control}
                      rules={{ 
                        required: "Phone number is required",
                        validate: (value) => isValidPhoneNumber(value) || "Please enter a valid phone number",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                        className="form-control"
                          value={value}
                          onChange={onChange}
                          defaultCountry="AE"
                          id="phone"
                        />
                      )}
                    />
                    {errors["phone"] && (
                      // <p className="error-message">Invalid Phone</p>
                      <div
                      className="text-danger"
                      style={{ marginTop: "0.25rem", fontSize: "80%" }}
                    >
                      {errors["phone"].message}
                    </div>
                    )}


                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-3 row">
                  <label htmlFor="email" className="col-md-4 col-form-label">
                    Email
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Please enter a valid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "0.25rem", fontSize: "80%" }}
                      >
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* {showUpdateButton ? ( */}
            <ActionButtonGroup
              isEdit={isEdit}
              resetCallback={resetCallback}
              submitting={false}
            />
            {/* ) : null}  */}
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateEditContact;
