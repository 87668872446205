
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { NotificationManager } from "react-notifications";
import ActionButtonGroup from "../../Employee/components/ActionButtonGroup";
import templateService from "../../../services/templateService";


const TemplateEditor = ({
  name: initialName,
  user,
  isEdit,
  reloadCallback,
  showUpdateButton,
}) => {
  const [name, setName] = useState(initialName || "");
  const [content, setContent] = useState("");
  const [nameError, setNameError] = useState("");
  const [contentError, setContentError] = useState("");
  const editorRef = useRef();

  useEffect(() => {
    if (isEdit && user && user.name) {
      setName(user.name);
      setContent(user.content);
    }
  }, [isEdit, user]);

  const saveFormData = async (data) => {
    try {
       templateService.createTemplate(data).then(async(response) => {
       
        // NotificationManager.success("Added new Template!", "Success!", 2000);
        if (response.data.status === 409){
          NotificationManager.error(response.data.message, "Error!", 2500)
        } else if (response.data.status === 500) {
          NotificationManager.error('Internal server error', "Error!", 2500);
        } else {
          NotificationManager.success(response.data.message, "Success!", 2500);
          resetForm();
        }
      })
     
    } catch (error) {
      console.error("Form submission error:", error);
      // NotificationManager.error("Error while creating new Template");
      NotificationManager.error(`${error.response.data.error}`, "Error!", 2500);
    }
  };

  const updateFormData = async (userId, data) => {
    data.id = userId;
    try {
      await templateService.updateTemplate(data.id, data);
      NotificationManager.success("Updated Template!", "Success!", 2000);
      reloadCallbackFn();
    } catch (error) {
      console.log(error);
      NotificationManager.error("Error while updating Template!", "Error!");
    }
  };

  const resetCallback = () => {
    resetForm();
  };

  const reloadCallbackFn = () => {
    reloadCallback();
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    [{ header: 1 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["link", "image"],
  ];

  const handleEditorChange = (content) => {
    setContent(content);
  };

  const resetForm = () => {
    setName("");
    setContent("");
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const data = { name, content };

    let isFormValid = true;

    if (!name) {
      setNameError("Template Name required");
      isFormValid = false;
    } else {
      setNameError("");
    }

    if (!content) {
      setContentError("Content required");
      isFormValid = false;
    } else {
      setContentError("");
    }

    if (isFormValid) {
      try {
        if (isEdit && user && user.id) {
          await updateFormData(user.id, data);
        } else {
          await saveFormData(data);
        }
      } catch (error) {
        console.error("Form submission error:", error);
      }
    }
  };

  return (
    <div>
      <form onSubmit={onSubmitHandler}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <div className="mb-3 row">
                  <label htmlFor="name" className="col-md-4 col-form-label">
                    Template Name
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    {nameError && (
                      <div
                        className="text-danger"
                        style={{ marginTop: "0.25rem", fontSize: "80%" }}
                      >
                        {nameError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label className="col-md-4 col-form-label">Content</label>
                
                  <ReactQuill
                    className="form-control"
                    style={{ minHeight: '150px'}} 
                    theme="snow"
                    modules={{ toolbar: toolbarOptions }}
                    value={content}
                    onChange={handleEditorChange}
                    // style={{ color: "black" }}
                    required
                    ref={editorRef}
                  />
                  {contentError && (
                    <div
                      className="text-danger"
                      style={{ marginTop: "0.25rem", fontSize: "80%" }}
                    >
                      {contentError}
                    </div>
                  )}
                
                <br />
              </div>
            </div>
          </div>
        </div>
        <ActionButtonGroup
          isEdit={isEdit}
          resetCallback={resetCallback}
          submitting={false}
        
        />
      </form>
    </div>
  );
};

export default TemplateEditor;
