import React, { useEffect } from "react";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import Modal from "../../../features/ui/modal/modal";
import Campaign from "./create";
import campaignService from "../../../services/campaignService";
import { blue } from "@mui/material/colors";
// import { campaignMockData } from "../../client/MockData";

const CampaignList = ({ name }) => {
  const [selectedRow, setSelectedRow] = useState(-1);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [modalTitle, setModalTitle] = useState([]);
  const [userData, setUserData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  //   const reloadCallback = () => {
  //     console.log('reloadCallback');
  //     fetchUsersData();
  //   }
  // const handleEdit = (id) => {
  //   console.log(`Edit campaign with ID: ${id}`);
  // };

  // const handleView = (id) => {
  //   console.log(`View campaign with ID: ${id}`);
  // };

  // const handleDelete = (id) => {
  //   console.log(`Delete campaign with ID: ${id}`);
  // };

  function getFrequencyLabel(value) {
    switch (value) {
      case "1":
        return "Weekly";
      case "2":
        return "Weekly twice";
      case "3":
        return "Monthly";
      case "4":
        return "Monthly twice";
      default:
        return "";
    }
  }

  async function fetchCampaignData() {
    campaignService
      .getCampaign()
      .then(async (response) => {
        console.log(response);
        await setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error("Error while fetching Campaign!", "Error");
      });
    // setData(campaignMockData);
  }

  useEffect(() => {
    fetchCampaignData();
  }, []);

  const hideModal = () => {
    setShow(false);
  };

  async function sendMail(id) {
    campaignService
      .sendCampaign(id)
      .then(async (response) => {
        console.log(response);
        // NotificationManager.success("Campaign send Successfully", "Success!");
        NotificationManager.success(response.data.message);
      })
      .catch((error) => {
        console.log(error);
        // NotificationManager.error("Error while sending Campaign!", "Error");
        NotificationManager.error(error.response.data.error);
      });
  }

  const userModal = async (user, editMode) => {
    console.log("User send to userModel: ", user);
    await setUserData(user);
    if (user && user.name) {
      await setModalIsOpen(true);
      await setIsEditMode(editMode);
    }
  };

  const reloadCallback = () => {
    console.log("reloadCallback");
    fetchCampaignData();
  };

  const handleDelete = (id, campaignName) => {
    const choice = window.confirm(
      "Do you want to delete '" + campaignName + "' ?"
    );
    if (choice) {
      deleteAction(id);
    }
  };

  const deleteAction = async (id) => {
    console.log("Inside deleteAction");
    campaignService
      .deleteCampaign(id)
      .then(async (response) => {
        console.dir(response);
        fetchCampaignData();
        NotificationManager.success("Deleted successfully!", "Success!");
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error("Error while deleting Campaign!", "Error!");
      });
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h4 className="fw-bold" style={{ textAlign: "-webkit-left" }}>
            <span className="text-muted fw-light"></span>
            {name}
          </h4>
        </div>
      </div>
      <div className="text-nowrap">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Date</th>
              {/* <th>Group Name</th> */}
              <th>Campaign Frequency</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {data.map((user, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => setSelectedRow(index)}
                  className={"clickable-row ".concat(
                    selectedRow === index ? "selected" : ""
                  )}
                >
                  <td>{user.campaignName}</td>
                  <td>{`${user.startDate}  to  ${user.endDate}`}</td>
                  {/* <td>{user.date}</td> */}
                  {/* <td>{user.to}</td> */}
                  {/* <td>{user.frequency}</td> */}
                  <td>{getFrequencyLabel(user.frequency)}</td>

                  {/* <td>
                    <span
                      onClick={() => {
                        userModal(user, false);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#exLargeModal"
                    >
                      <i class="menu-icon tf-icons bx bx-show"></i>
                    </span>
                  </td> */}
                  <td>
                    <span
                      onClick={() => {
                        userModal(user, true);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#exLargeModal"
                    >
                      <i class="menu-icon tf-icons bx bx-edit"></i>
                    </span>
                  </td>
                  <td>
                    <span
                      onClick={() => {
                        handleDelete(user.id, user.campaignName);
                      }}
                    >
                      <i class="menu-icon tf-icons bx bx-trash"></i>
                    </span>
                  </td>

                  <td>
                    <span
                      onClick={() => {
                        sendMail(user.id);
                      }}
                      // data-bs-toggle="modal"
                      // data-bs-target="#exLargeModal"
                    >
                      <button
                        style={{
                          backgroundColor: "#3b5998",
                          color: "white",
                          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                          border: "none",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          outline: "none",
                        }}
                      >
                        Send
                      </button>
                    </span>
                  </td>
                 
                </tr>
                
              );
            })}
          </tbody>
        </table>
      </div>

      <Modal
        show={show}
        isOpen={modalIsOpen && userData}
        handleClose={hideModal}
        id="exLargeModal"
        data={{ title: modalTitle }}
      >
        <Campaign
          isEdit={true}
          user={userData}
          reloadCallback={reloadCallback}
          showUpdateButton={isEditMode}
        />
      </Modal>
    </>
  );
};

export default CampaignList;
